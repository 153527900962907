
import { importPageToShop } from '@/api/live/live.api'
import { PageDetail } from '@/api/live/live.type'
import { useFacebookPageStore } from '@/pinia/facebook-page/facebook-page.pinia'
import { useLiveStore } from '@/pinia/live/live.pinia'
import { useShopStore } from '@/pinia/shop/shop.pinia'
import { useWindowSize } from '@/use/useWindowSize'
import { computed, defineComponent, ref } from 'vue'

export default defineComponent({
	name: 'PageSelectDialog',
	props: {
		modelValue: {
			type: Boolean,
			default: false,
		}
	},
	setup(_, { emit }) {
		const isSubmitting = ref(false)
		const selectedPage = ref([])
		const facebookPageStore = useFacebookPageStore()
		const liveStore = useLiveStore()
		const shopStore = useShopStore()
		const { mobileMode: isMobile } = useWindowSize()
		const pageList = computed(() => facebookPageStore.pageList)
		const isReactNativeWebview = computed(() => window.ReactNativeWebView)
		const handleSelectionChange = (value: any) => {
			selectedPage.value = value
		}
		const onConfirmConnect = () => {
			//
			const shopId = shopStore.selectedShopId as string

			const pages: PageDetail[] = selectedPage.value.map((page: any) => ({
				name: page.name,
				accessToken: page.accessToken,
				pageId: page.id,
				imageUrl: page.picture?.data.url
			}))
			isSubmitting.value = true
			// Hard code for test only index 0
			importPageToShop({ shopId, pages })
				.finally(() => {
					isSubmitting.value = false
					facebookPageStore.getAllPageInShop()
					
					emit('update:modelValue', false)
				})
		}

		return {
			isSubmitting,
			pageList,
			selectedPage,
			handleSelectionChange,
			onConfirmConnect,
			isMobile,
			isReactNativeWebview,
		}
	},
})
