
import { defineComponent } from 'vue'
import { useWindowSize } from '@/use/useWindowSize'

export default defineComponent({
	name: 'DialogPermissionAble',
	props: {
		isWarning: {
			type: Boolean,
			default: false
		},
	},
	emits: ['update:isWarning'],
	setup(_, { emit }) {
		const { mobileMode: isMobile } = useWindowSize()

		const handleClose = () => {
			emit('update:isWarning', false)
		}

		return {
			handleClose,
			isMobile
		}
	},
})
