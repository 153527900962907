import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2f9fad2c"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  style: {"color":"red","font-size":"12px"}
}
const _hoisted_2 = { class: "dialog-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_image = _resolveComponent("el-image")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createBlock(_component_el_dialog, {
    "model-value": _ctx.modelValue,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update:modelValue', false))),
    title: "เลือกเพจที่ต้องการเชื่อมต่อกับระบบ",
    "close-on-press-escape": false,
    "close-on-click-modal": false,
    fullscreen: _ctx.isMobile,
    "show-close": !_ctx.isReactNativeWebview,
    center: ""
  }, {
    footer: _withCtx(() => [
      _createElementVNode("span", _hoisted_2, [
        _createVNode(_component_el_button, {
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', false)))
        }, {
          default: _withCtx(() => [
            _createTextVNode("ยกเลิก")
          ]),
          _: 1
        }),
        _createVNode(_component_el_button, {
          disabled: _ctx.selectedPage.length < 1,
          loading: _ctx.isSubmitting,
          onClick: _ctx.onConfirmConnect,
          type: "primary"
        }, {
          default: _withCtx(() => [
            _createTextVNode("(" + _toDisplayString(_ctx.selectedPage.length) + ") ยืนยัน", 1)
          ]),
          _: 1
        }, 8, ["disabled", "loading", "onClick"])
      ])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_el_table, {
        ref: "multipleTable",
        data: _ctx.pageList,
        style: {"width":"100%"},
        onSelectionChange: _ctx.handleSelectionChange
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_table_column, {
            type: "selection",
            selectable: (row) => row.importAble,
            width: "55"
          }, null, 8, ["selectable"]),
          _createVNode(_component_el_table_column, {
            label: "",
            width: "100"
          }, {
            default: _withCtx((scope) => [
              _createVNode(_component_el_image, {
                src: scope.row.picture?.data?.url
              }, null, 8, ["src"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_table_column, {
            label: "ชื่อเพจ",
            prop: "name"
          }, {
            default: _withCtx((scope) => [
              _createElementVNode("div", null, _toDisplayString(scope.row.name), 1),
              (!scope.row.importAble)
                ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(`(เพจนี้ได้เชื่อมต่อกับร้านค้าที่มีในระบบแล้ว กรุณาลบจากร้านค้าที่เชื่อมต่อหากต้องการเชื่อมต่อ)`), 1))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["data", "onSelectionChange"])
    ]),
    _: 1
  }, 8, ["model-value", "fullscreen", "show-close"]))
}