
import { computed, defineComponent, onMounted, onUnmounted, ref, watch } from 'vue'
import PageSelectDialog from '@/components/live-stream/page/PageSelectDialog.vue'
import DialogRevokePage from '@/components/facebook/DialogRevokePage.vue'
import SelectBlock from '@/components/core/SelectBlock.vue'
import { deletePageFromShop, importPageToShop } from '@/api/live/live.api'
import { PageDetail } from '@/api/live/live.type'
import { KycStatus } from '@/api/type'
import DialogPermissionAble from '@/components/live-stream/page/DialogPermissionAble.vue'
import { useShopStore } from '@/pinia/shop/shop.pinia'
import { useUserStore } from '@/pinia/user/user.pinia'
import { useFacebookPageStore } from '@/pinia/facebook-page/facebook-page.pinia'
import { useRouter, useRoute } from 'vue-router'
import { ElMessageBox } from 'element-plus'
import { CurrentPage } from '@/pinia/facebook-page/types'
import dayjs from '@/utils/dayjs'
import { REACT_NATIVE_EVENT, INCOMING_REACTIVE_NATIVE_EVENT, useReactNativeEvent } from '@/use/useReactNativeEvent'
import { ElNotification } from 'element-plus'
declare global {
    interface Window {
        FB: any;
    }
}

export default defineComponent({
	name: 'FacebookPageConnect',
	components: {
		SelectBlock,
		PageSelectDialog,
		DialogPermissionAble,
		DialogRevokePage,
	},
	props: {
		isShowUnreadTotal: {
			type: Boolean,
			default: false
		},
		showShopSetting: {
			type: Boolean,
			default: false
		},
		pageId: {
			type: String,
			default: ''
		}
	},
	emits: ['onPageClick'],
	setup (_, { emit }) {
		const isDialogTutorialShow = ref<boolean>(false)
		const facebookPageStore = useFacebookPageStore()
		const shopStore = useShopStore()
		const userStore = useUserStore()
		const { emitEvent, listenerEventMessage, removeListenerEventMessage } = useReactNativeEvent()
		const router = useRouter()
		const route = useRoute()

		const isShowDialogSelectPage = ref(false)
		const pageInShop = computed(() => facebookPageStore.shopPageList)
		const isLoading = ref(false)
		const isPermissionAble = ref<boolean>(false)
		const testEventReactNativeData = ref()
		const isRevokePage = ref<boolean>(false)
		const isReactNativeWebview = computed(() => window.ReactNativeWebView)

		const fetchPageInShop = async () => {
			const isChatPage = route.path.includes('/chat')
			const isLivePage = route.path.includes('/live-stream')
			const isCommentPage = route.path.includes('/page-comment')

			try {
				isLoading.value = true
				if (isChatPage) {
					await facebookPageStore.getAllPageInShopChat()
				}
				else if (isLivePage) {
					await facebookPageStore.getAllPageInShopLive()
				}
				else if (isCommentPage) {
					await facebookPageStore.getAllPageInShopMent()
				} else {
				// get default
					await facebookPageStore.getAllPageInShop()
				}
			} catch (error) {
				// clear expired
				facebookPageStore.expired = null
			} finally {
				//
				isLoading.value = false
			}
		}
		
		const onConnectPage = () => {
			// react native webview
			if (window.ReactNativeWebView) {
				emitEvent(REACT_NATIVE_EVENT.ON_CONNECT_PAGE)
				return
			}
			window.FB.login(async (response: any) => {
				if (response.status === 'connected') {
					connectFacebookWithToken(response.authResponse.accessToken)
				}
			},
			{
				scope: 'public_profile,email,pages_show_list,pages_read_engagement,pages_messaging,pages_manage_metadata,pages_manage_engagement,pages_read_user_content,page_events,business_management'
			})			
		}

		const onRevokePage = async () => {
			isRevokePage.value = true
		}

		const connectFacebookWithToken = async (accessToken: string) => {
			isLoading.value = true
			try {
				const pageList: any = await facebookPageStore.getAllpage(accessToken)
				if(pageList.permissionAble){
					if (pageList.availablePages.length === 1 && pageList.availablePages[0]?.importAble) {
						// Automatical import page
						const shopId = shopStore.selectedShopId as string

						const pages: PageDetail[] = pageList.availablePages.map((page: any) => ({
							name: page.name,
							accessToken: page.accessToken,
							pageId: page.id,
							imageUrl: page.picture?.data.url
						}))
						// Hard code for test only index 0
						importPageToShop({ shopId, pages })
							.finally(() => {
								isLoading.value = false
								fetchPageInShop()	
							})
					} else {
						isLoading.value = false
						isShowDialogSelectPage.value = true
					}
				} else {
					isLoading.value = false
					isPermissionAble.value = true
				}
			} catch (error) {
				ElNotification({
					title: 'แจ้งเตือน',
					message: 'ไม่สามารถเชื่อมเพจได้ กรุณาติดต่อแอดมิน',
					type: 'warning',
				})
				console.error(error)
			} finally {
				facebookPageStore.isRevoke = true
				isLoading.value = false
			}
		}

		const incomingReactNativeEventHandle = (eventNative: any) => {
			//
			try {
				const eventData = JSON.parse(eventNative?.data)

				testEventReactNativeData.value = eventData

				if (eventData.eventName === INCOMING_REACTIVE_NATIVE_EVENT.ON_SENT_CONNECT_FACEBOOK_TOKEN) {
					connectFacebookWithToken(eventData.data)
				}
			} catch (error) {
				//
				console.log('error connect', error)
			}
		}


		const onCheckIsFbAlreadyLogin = () => {
			window.FB.getLoginStatus((response: any) => {
				//
			})
		}

		const onDeletePage = async (page: CurrentPage) => {

			const isConfirm = await ElMessageBox.confirm(
				'ยกเลิกการเชื่อมต่อเพจกับระบบ',
				{
					confirmButtonText: 'ยืนยัน',
					cancelButtonText: 'ยกเลิก',
					type: 'warning',
					showClose: !isReactNativeWebview.value
				}
			)
			if (!isConfirm) return
			

			try {
				isLoading.value = true
				await deletePageFromShop(page?._id)
				await fetchPageInShop()	
			} catch (error) {
				// let it go
			} finally {
				//
				isLoading.value = false
			}
		}

		const onPageClick = (page: any) => {
			emit('onPageClick', page)
		}

		watch(
			() => route.query.pageId,
			() => {
				fetchChatInboxDataAndPageInShop()
			}
		)

		const fetchChatInboxDataAndPageInShop = async () => {
			isLoading.value = true
			try {
				await fetchPageInShop()
				if(route.query.pageId && route.query._conversationId) {
					const pageInfo = pageInShop.value.find(e => {
						return e?._id === route.query.pageId
					})
					if(pageInfo) {
						router.push({
							path: `/chat/${pageInfo?.pageId}`,
							query: { ...route.query, _conversationId: route.query._conversationId }
						})
					}
				}
			} catch (error) {
				console.error(error)
			} finally {
				isLoading.value = false
			}
		}

		onMounted(async () => {

			listenerEventMessage(incomingReactNativeEventHandle)
			// fetch after listen event
			fetchChatInboxDataAndPageInShop()
		})

		onUnmounted(() => {
			removeListenerEventMessage(incomingReactNativeEventHandle)
		})

		return {
			isDialogTutorialShow,
			onConnectPage,
			onRevokePage,
			onCheckIsFbAlreadyLogin,
			onDeletePage,
			fetchPageInShop,
			isShowDialogSelectPage,
			isRevokePage,
			pageInShop,
			isLoading,
			isPermissionAble,
			onPageClick,
			facebookPageStore,
			dayjs,
			route,
			testEventReactNativeData,
		}
	}
})
