export enum RECONNECT_TYPE {
    LIVE = 'LIVE',
    SYNC_CHAT = 'SYNC_CHAT',
    POST_REPLY = 'POST_REPLY',
}
export interface CurrentPage {
	_id: string;
	name: string;
	pageId: string;
    imageUrl: string;
    importBy: string;
    followerTotal: number;
    likeTotal: number;
    unreadTotal: number;
    reconnects: RECONNECT_TYPE[]
}

export type State = {
    shopPageList: CurrentPage[],
    pageList: [],
    selectedPages: [],
    expired: number | null,
    isRevoke: boolean,
}